.mainContainer {
  margin: 80px 20px 0px 20px;
  /* padding: 0px 10px; */
  display: flex;
}

.container-one {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
}

.container-two {
  width: 50%;
}

.container-one .text-header {
  font-size: 44px;
  text-align: start;
  font-weight: 700;
  width: 100%;
}

.zigzag-image {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
}

.paragraph-main {
  font-size: 20px;
  text-align: start;
  width: 100%;
  margin-top: 24px;
}

.button-for-ios-android {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 25px;
  margin-top: 24px;
  width: 100%;
}

.download-icon-layout {
  display: flex;
  flex-direction: row;
}

.button-ios {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 230px;
  width: 100%;
  font: 600;
  background: #ff5200;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
}
.button-android {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 230px;
  width: 100%;
  font: 600;
  background: #ff5200;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
}

.start-using-desktop {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
}
/* responsive */
@media only screen and (max-width: 1100px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .download-icon-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 40px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .download-app-layout {
    max-width: 460px;
  }

  .paragraph-main {
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    /* font-size: 14px; */
  }

  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 535px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .download-icon-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .download-app-layout {
    width: 360px;
  }

  .container-one .text-header {
    font-size: 35px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 15px;
  }

  .button-for-ios-android .button-android {
    font-size: 15px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}

@media only screen and (max-width: 485px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
  .download-app-layout {
    max-width: 460px;
    width: 100%;
  }
  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 32px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 14px;
  }

  .button-for-ios-android .button-android {
    font-size: 14px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .download-app-layout {
    max-width: 460px;
    width: 100%;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 12px;
  }

  .button-for-ios-android .button-android {
    font-size: 12px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 429px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .download-app-layout {
    max-width: 460px;
    width: 100%;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 28px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 12px;
  }

  .button-for-ios-android .button-android {
    font-size: 12px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 409px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .download-app-layout {
    max-width: 460px;
    width: 100%;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 26px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 12px;
  }

  .button-for-ios-android .button-android {
    font-size: 12px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 389px) {
  .mainContainer {
    margin: 80px 20px 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .download-app-layout {
    max-width: 460px;
    width: 100%;
  }

  .container-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .container-two {
    width: 100%;
    display: none;
  }

  .container-one .text-header {
    font-size: 22px;
    text-align: center;
    width: 100%;
  }
  .zigzag-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paragraph-main {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .button-for-ios-android {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-for-ios-android .button-ios {
    font-size: 12px;
  }

  .button-for-ios-android .button-android {
    font-size: 12px;
  }
  .start-using-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
