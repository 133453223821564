.button-for-ios-android-boost-test {
    gap: 25px;
    margin-top: 24px;
  }
  
  .button-ios-boost-test {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    font: 600;
    background: #ff5200;
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
  }
  .button-android-boost-test {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    font: 600;
    background: #ff5200;
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
  }
  
  .start-using-desktop-boost-test {
    margin-top: 16px;
    display: flex;
    justify-content: start;
    align-items: start;
  }