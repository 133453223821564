@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

/* For Webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #ff5200; /* Scrollbar color */
  border-radius: 8px; /* Rounds the edges of the scrollbar */
  border: 2px solid #f0f0f0; /* Padding around the scrollbar thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ff5200 #f0f0f0;
}

.clashg {
  height: 100% !important;
}
